<!--
 * @Descripttion: 我的保存列表
 * @version: 
 * @Author: alex.yang
 * @Date: 2022-09-27 09:05:15
 * @LastEditors: alex.yang
 * @LastEditTime: 2022-09-30 16:07:06
-->
<template>
    <div class="mySaveList">
        <div class="title-wrap">
            <div class="tag"></div>
            <div class="title">我的保存列表</div>
        </div>
        <div class="case-wrap">
            <div class="screen-wrap">
                <div class="sw-left">
                    <div class="search-item">
                        <el-input clearable v-model="keyword" placeholder="请输入搜索内容"></el-input>
                        <el-button class="btns" @click="clickSearch" type="primary">搜索</el-button>
                    </div>
                </div>
            </div>
            <div class="table-wrap" v-loading="loading">
                <el-table :data="tableData" border style="width: 100%" @selection-change="handleSelectionChange">
                    <!-- <el-table-column align="center" type="selection" width="55"></el-table-column> -->
                    <el-table-column align="center" prop="imgs" label="全景图片" :show-overflow-tooltip="true">
                        <template slot-scope="scope">
                            <img :src="scope.row.imgs[0]" height="50" alt="">
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="brand" label="全景名称" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column align="center" prop="model" label="产品型号" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column align="center" prop="preview" label="浏览次数"></el-table-column>
                    <el-table-column align="center" prop="dianzan" label="点赞次数"></el-table-column>
                    <el-table-column align="center" fixed="right" label="操作" width="140">
                        <template slot-scope="scope">
                            <div class="operation-wrap">
                                <el-tooltip class="item" effect="dark" content="全景码" placement="top">
                                    <i @click="clickPreviewCode(scope.row)" class="iconfont el-icon-s-grid"></i>
                                </el-tooltip>
                                <el-tooltip class="item" effect="dark" content="预览" placement="top">
                                    <i @click="clickPreview(scope.row)" class="iconfont el-icon-view"></i>
                                </el-tooltip>
                                <el-tooltip class="item" effect="dark" content="编辑" placement="top">
                                    <i @click="clickEdit(scope.row)" class="iconfont el-icon-edit"></i>
                                </el-tooltip>
                                <el-tooltip class="item" effect="dark" content="删除" placement="top">
                                    <i @click="clickDelete(scope.row)" class="iconfont el-icon-delete"></i>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="pagination-wrap">
                    <el-pagination
                        @current-change="handleCurrentChange"
                        :current-page.sync="currentPage"
                        :page-size="10"
                        layout="total, prev, pager, next"
                        :total="total">
                    </el-pagination>
                </div>
            </div>
        </div>
        <el-dialog
            :before-close="handleCloseScenarios" 
            :close-on-click-modal="false"
            :visible.sync="isAddScenarios" 
            custom-class="dialog-wrap">
            <div class="title">编辑全景</div>
            <div class="content-wrap" v-loading="addLoading">
                <el-form :model="scenariosForm" :rules="rulesScenarios" ref="scenariosForm" label-width="100px" class="demo-ruleForm">
                    <el-form-item label="全景名称" prop="brand">
                        <el-input v-model="scenariosForm.brand" placeholder="请输入全景名称"></el-input>
                    </el-form-item>
                    <el-form-item label="产品型号" prop="model">
                        <el-input v-model="scenariosForm.model" placeholder="请输入产品型号"></el-input>
                    </el-form-item>
                    <div class="btn-wrap">
                        <el-button :loading="btnLoading" type="primary" @click="submitFormEdit">编 辑</el-button>
                    </div>
                </el-form>
            </div>
        </el-dialog>
        <el-dialog
            :before-close="handleCloseCode" 
            :close-on-click-modal="false"
            :visible.sync="isCodeScenarios" 
            custom-class="code-dialog-wrap">
            <div class="code-wrap">
                <!--<img :src="code" alt="">
                <div class="model">{{model}}</div>-->
                <canvas id="myCanvas" width="140" height="156" style="border:1px solid #d3d3d3;background:#ffffff;">
                </canvas>

                <el-button @click="godwnimg" type="">下载</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import pageMixin from '@/internal/pageMixin'
import common from "@/utils/common.js"
export default {
    mixins:[pageMixin],
    data () {
        return {
            pageMixin_index:'mySaveList',
            tableData: [],
            loading: false,
            currentPage: 1,
            total: 0,
            keyword: '',
            isAddScenarios: false,
            addLoading: false,
            btnLoading: false,
            scenariosForm: {
                id: '',
                brand: '',
                model: ''
            },
            rulesScenarios: {
                brand: [
                    { required: true, message: '请输入全景名称', trigger: 'blur' },
                ],
                model: [
                    { required: true, message: '请输入产品型号', trigger: 'blur' },
                ],
            },
            code: '',
            title: '',
            isCodeScenarios: false
        }
    },
    mounted () {
        this.getSceneList();
    },
    methods: {
        // 下载图片
        godwnimg(){
            var canvas = document.getElementById("myCanvas");
            if (window.navigator.msSaveOrOpenBlob) {//ie浏览器
            var imgData = canvas.msToBlob();
            var blobObj = new Blob([imgData]);
            window.navigator.msSaveOrOpenBlob(blobObj, title+".png");
            } else {//谷歌火狐浏览器
            this.downLoad(canvas.toDataURL("image/png"));
            }
        },
        downLoad(url) {
            var oA = document.createElement("a");
            oA.download = this.title;// 设置下载的文件名，默认是'下载'
            oA.href = url;
            oA.className = "qrcode"
            document.body.appendChild(oA);
            oA.click();
            oA.remove(); // 下载之后把创建的元素删除
        },
        handleCloseCode(){
            this.isCodeScenarios = false;
            
        },
        clickPreviewCode(row){
            this.isCodeScenarios = true;
            
            setTimeout(()=>{
                var c=document.getElementById("myCanvas");
                var ctx=c.getContext("2d");
                var img=new Image();
                img.setAttribute('crossOrigin', 'anonymous');
                var title = this.title = row.model
                img.src='https://zdt.taocitong.top/api/capi/qrcode?data='+ encodeURIComponent(row.link);

                ctx.rect(0,0,140,156);
                ctx.fillStyle="white";
                ctx.fill();

                img.onload = function(){
                    ctx.drawImage(img,0,0,140,140);
                    ctx.fillStyle="black";
                    ctx.font="14px Verdana";
                    ctx.fillText(title,10,146,200);
                }
            },100);

            


        },
        handleSelectionChange(row){
            console.log(row);
        },
        // 编辑
        submitFormEdit(){
            this.$refs.scenariosForm.validate((valid) => {
                if (valid) {
                    this.btnLoading = true;
                    common.connect('/distributorpcv1/scene/updateMyScene',this.scenariosForm,(res)=>{
                        if(res.isSuccess == 1){
                            this.getSceneList();
                            this.isAddScenarios = false;
                        }
                        this.btnLoading = false;
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        // 获取数据列表
        getSceneList(){
            let params = {
                page: this.currentPage,
                keyword: this.keyword
            }
            this.loading = true;
            common.connect('/distributorpcv1/scene/myScene',params,(res)=>{
                res.data.list.forEach(v => {
                    v.imgs = JSON.parse(v.imgs)
                    v.link = 'https://vr2.taocitong.top/viewer/vr.html?vr_id='+v.vr_id+'&share_id=-1&shop_id='+v.shop_id
                });
                this.total = res.data.count;
                this.tableData = res.data.list;
                this.loading = false;
            })
        },
        clickSearch(){
            this.currentPage = 1;
            this.getSceneList();
        },
        // 预览
        clickPreview(row){
            localStorage.setItem('link',row.link)
            this.$router.push("/panoramaView");
        },
        // 删除
        clickDelete(row){
            this.$confirm('此操作将永久删除该全景, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                common.connect('/distributorpcv1/scene/deleteMyScene',{id: row.id},(res)=>{
                    if(res.isSuccess == 1){
                        this.$message.success(res.msg)
                        this.getSceneList();
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
            
        },
        // 编辑
        clickEdit(row){
            this.isAddScenarios = true;
            this.scenariosForm.id = row.id;
            this.scenariosForm.brand = row.brand;
            this.scenariosForm.model = row.model;
        },
        handleCurrentChange(val){
            this.currentPage = val;
            this.getSceneList();
        },
        // 关闭弹窗
        handleCloseScenarios(){
            this.isAddScenarios = false;
        },
    },
}
</script>

<style lang='scss'>
.mySaveList{
    background: rgb(240, 242, 245);
    padding: 10px 25px;
    height: calc(100vh - 80px);
    overflow-y: auto;
    .title-wrap{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 5px 0;
        .tag{
        width: 4px;
        height: 20px;
        background: #409EFF;
        border-radius: 10px;
        }
        .title{
        font-size: 18px;
        font-weight: bold;
        padding-left: 10px;
        }
    }
    .case-wrap{
        background: #fff;
        padding: 20px;
        margin: 20px 0;
        border-radius: 6px;
        .screen-wrap{
            display: flex;
            justify-content: space-between;
            .sw-left{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                .screen-item{
                    margin-right: 15px;
                    margin-bottom: 20px;
                }
                .search-item{
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    margin-bottom: 20px;
                    .el-input{
                        width: 220px;
                        margin-right: 10px;
                    }
                }
            }
        }
        .table-wrap{
            .image{
                width: 40px;
                cursor: pointer;
            }
            .operation-wrap{
                display: flex;
                justify-content: center;
                .iconfont{
                    font-size: 16px;
                    padding: 0 10px;
                    cursor: pointer;
                }
            }
            .pagination-wrap{
                display: flex;
                justify-content: flex-end;
                align-items: center;
                padding-top: 20px;
            }
        }
    }
    .code-dialog-wrap{
        width: 260px;
        .code-wrap{
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
    .dialog-wrap{
        width: 650px;
        .title{
            font-size: 16px;
            color: #000;
            padding-bottom: 10px;
            margin-left: 20px;
            margin-top: -5px;
        }
        .content-wrap{
            max-height: 80vh;
            overflow-y: auto;
            padding-top: 20px;
            .el-input{
                width: 530px
            }
            .btn-wrap{
                display: flex;
                justify-content: center;
                align-items: center;
                padding-bottom: 20px;
            }
        }
      
    }
    .el-dialog{
        margin: 0;
        margin-top: 0vh !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        padding-bottom: 10px;
    }
}
</style>
